import throttle from "lodash.throttle";

function ensureRange(value: number, min: number, max: number): number {
  return Math.min(Math.max(value, min), max);
}

document.addEventListener("turbo:load", () => {
  document.querySelectorAll(".slideshow-container").forEach((s) => {
    const slideshow = s as HTMLDivElement;
    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    slideshow.addEventListener("mousedown", (e) => {
      isDown = true;

      slideshow.classList.add("grabbing");
      startX = e.pageX - slideshow.offsetLeft;
      scrollLeft = slideshow.scrollLeft;
    });
    slideshow.addEventListener("mouseleave", () => {
      isDown = false;
      slideshow.classList.remove("grabbing");
    });
    slideshow.addEventListener("mouseup", () => {
      isDown = false;
      slideshow.classList.remove("grabbing");
    });
    slideshow.addEventListener("mousemove", (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slideshow.offsetLeft;
      const walk = (x - startX) * 5; //scroll-fast
      slideshow.scrollLeft = scrollLeft - walk;
    });
    if (slideshow.classList.contains("gallery")) {
      const thumbs = slideshow.nextElementSibling;
      if (thumbs && thumbs.classList.contains("gallery-thumbs")) {
        const syncThumbs = throttle(() => {
          const sl = slideshow.scrollLeft - slideshow.clientWidth / 2;
          const idx = Array.from(slideshow.children).findIndex((c) => (c as HTMLDivElement).offsetLeft >= sl);
          const child = thumbs.children[idx] as HTMLDivElement;
          // set all but the current one to active
          if (!child || !child.classList.contains("active"))
            Array.from(thumbs.children).forEach((c: Element, i) => {
              (c as HTMLDivElement).classList.toggle("active", idx == i);
            });
          // make sure the current one is in viewport (of container, don't scroll to container)
          if (child) {
            thumbs.scrollLeft = ensureRange(
              thumbs.scrollLeft,
              child.offsetLeft + child.offsetWidth - thumbs.clientWidth,
              child.offsetLeft,
            );
          }
        }, 200);
        slideshow.addEventListener("scroll", syncThumbs);
        syncThumbs();
      }
    }
  });

  document.querySelectorAll(".gallery-thumbs").forEach((s) => {
    const thumbsContainer = s as HTMLDivElement;
    const slideshow = thumbsContainer.previousElementSibling;
    if (!slideshow) return;

    thumbsContainer.addEventListener("mouseover", (event) => {
      let t = event.target as HTMLElement;
      if (!t.classList.contains("slide")) {
        while (t.parentElement && !t.classList.contains("slide") && !t.classList.contains("slideshow"))
          t = t.parentElement;
      }
      if (t.classList.contains("slide") && !t.classList.contains("grabbing")) {
        var i = 0;
        while ((t = t.previousElementSibling as HTMLElement) != null) i++;
        slideshow.scrollLeft = (slideshow.children[i] as HTMLElement).offsetLeft;
      }
    });
  });
});
